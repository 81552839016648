import { debounce } from 'lodash';

import { IWidgetController } from '@wix/native-components-infra/dist/src/types/types';

import {
  ISearchResultsWixCode,
  IWidgetControllerConfig,
} from '../../../../lib/platform.types';
import { createSearchPlatformBiLogger } from '../bi';
import { convertResponseToSuggestions } from './convertResponseToSuggestions';
import {
  I$W,
  I$WEvent,
  I$WResult,
  ISearchBoxWixCode,
} from './searchAppControllerFactory.types';
import { Spec } from '../../../../lib/specs';

export async function searchAppControllerFactory(
  controllerConfig: IWidgetControllerConfig,
): Promise<IWidgetController> {
  const {
    buildSearchAllUrl,
    getCategoryList,
    platformAPIs,
    searchLocation,
    searchSDK,
    wixCodeApi,
    experiments,
  } = controllerConfig;
  // TODO: cleanup types when resolved issue with type
  // https://github.com/wix-private/site-search/issues/92
  const $w: I$W = controllerConfig.$w;
  const isMobile = wixCodeApi.window.formFactor === 'Mobile';

  const isSiteViewMode = wixCodeApi.window.viewMode === 'Site';
  const isDemoContent = !isSiteViewMode;

  const bi = createSearchPlatformBiLogger(
    platformAPIs,
    wixCodeApi,
    controllerConfig.reportError,
  );

  function getRelativeUrl(url: string): string {
    const relativeUrl = url.replace(wixCodeApi.location.baseUrl, '');
    return relativeUrl === '' ? '/' : relativeUrl;
  }

  return {
    async pageReady() {
      const absoluteUrl = await searchLocation.getSearchResultsAbsoluteUrl();

      const searchBoxes: I$WResult<ISearchBoxWixCode> = $w('@searchBox');
      const searchResults: I$WResult<ISearchResultsWixCode> = $w(
        '@searchResults',
      );

      const hasSearchBoxOnPage = searchBoxes.length > 0;
      const isOnSearchResultsPage = searchResults.length > 0;

      if (!hasSearchBoxOnPage) {
        return;
      }

      searchBoxes.onClear(() => {
        bi.resetRequestCorrelationId();
        // TODO: trigger biEvent here.
        // https://jira.wixpress.com/browse/SER-1345
      });

      searchBoxes.onSubmit((e) => {
        const searchBox = e.target;
        const searchQuery = searchBox.value;

        bi.searchSubmit({
          isDemoContent,
          searchQuery,
        });

        searchBox.value = '';
        searchBox.suggestions = null;

        if (!isOnSearchResultsPage) {
          // NOTE: navigation with not empty search query works correctly only in `Site` view mode
          // TODO: we could navigate with searchQuery in preview mode when `sv_editorNativeComponents` opened
          void searchLocation.navigateToSearchResults({
            query: isSiteViewMode ? searchQuery : '',
          });
        } else {
          // NOTE: editor version of searchResults component, doesn't return valid controller instance
          // so `changeQuery` call does not affect search results component
          // should be fixed when `sv_editorNativeComponents` opened
          // https://github.com/wix-private/site-search/issues/130
          searchResults.changeQuery(searchQuery);
        }
      });

      /**
       * Clear all suggestions if mobile.
       * If switch to desktop editor script will set demo suggestion again
       */
      if (isMobile) {
        searchBoxes.suggestions = null;
      }

      if (!isDemoContent && !isMobile) {
        const loadSuggestions = (minimumSearchTermLength: number) => async (
          e: I$WEvent<ISearchBoxWixCode>,
        ) => {
          const searchBox = e.target;
          const searchQuery = searchBox.value;

          if (!searchBox.suggestionsEnabled) {
            return;
          }

          if (!searchQuery) {
            bi.resetRequestCorrelationId();
          }

          if (searchQuery.length < minimumSearchTermLength) {
            searchBox.suggestions = null;
            return;
          }

          const biSearchBoxSuggestionsRequestFinished = bi.searchBoxSuggestionsRequestStarted(
            { searchQuery },
          );

          try {
            const federatedResponse = await searchSDK.getFederatedSuggestions({
              query: searchQuery,
              limit: 4,
            });
            const categoryList = await getCategoryList();

            const federatedSuggestions = convertResponseToSuggestions({
              federatedResponse,
              categoryList,
              searchQuery,
              footerUrl: await buildSearchAllUrl(searchQuery),
              searchLocation,
              absoluteUrl,
            });

            if (searchQuery === searchBox.value) {
              searchBox.suggestions = federatedSuggestions;
            }

            biSearchBoxSuggestionsRequestFinished({
              success: true,
              suggestions: federatedSuggestions.items,
            });
          } catch (error) {
            biSearchBoxSuggestionsRequestFinished({
              success: false,
              error: error.toString(),
              suggestions: [],
            });

            controllerConfig.reportError(error);

            throw error;
          }
        };

        const isShorterSearchTermEnabled = experiments.enabled(
          Spec.ShorterSearchTerm,
        );

        searchBoxes.onChange(
          debounce(
            loadSuggestions(isShorterSearchTermEnabled ? 1 : 3),
            isShorterSearchTermEnabled ? 100 : 300,
          ),
        );

        searchBoxes.onSubmit((e) => {
          const searchBox = e.target;
          searchBox.suggestions = null;
        });

        searchBoxes.onSuggestionSelect((e) => {
          const searchBox = e.target;
          const { title, data } = e.syntheticEvent;
          const { url, query, globalIndex, groupId, type } = data;

          if (type === 'item') {
            bi.searchBoxSuggestionClick({
              title,
              url,
              searchQuery: query,
              index: globalIndex,
              documentType: groupId,
              suggestions: searchBox.suggestions?.items || [],
            });
          }
          // Compatibility with Bolt component (clicking on a suggestions group uses onSuggestionSelect()
          // in Bolt and onSuggestionGroupSelect() in Thunderbolt).
          else {
            bi.searchBoxSuggestionShowAllClick({
              searchQuery: query,
              documentType: groupId,
            });
          }

          searchBox.suggestions = null;

          wixCodeApi.location.to?.(getRelativeUrl(url));
        });

        searchBoxes.onSuggestionsFooterClick((e) => {
          const query = e.target.value;
          bi.searchBoxSuggestionSearchAllClick({
            searchQuery: query,
          });
          searchBoxes.suggestions = null;
          searchLocation
            .navigateToSearchResults({ query })
            .catch(controllerConfig.reportError);
        });

        searchBoxes.onSuggestionGroupSelect((e) => {
          /* SearchBox::Bolt does not use `onSuggestionGroupSelect`:
           * https://github.com/wix-private/wix-ui-santa/search?q=onSuggestionGroupSelect
           * https://github.com/wix-private/site-search/search?q=handleSuggestionGroupSelect
           *
           * However old implementation of API leaked to the users at least once.
           *
           * In Bolt implementation of SearchBox, fields (url, query, groupId) were defined
           * directly on `syntheticEvent` (without `data` layer).
           *
           * In SearchBox::TB we moved to unified solution and introduced `syntheticEvent.data`.
           *
           * This guard should help to avoid unexpected failures in the user code.
           */
          if (!e.syntheticEvent?.data) {
            return;
          }

          const { url, query, groupId } = e.syntheticEvent.data;
          searchBoxes.suggestions = null;

          bi.searchBoxSuggestionShowAllClick({
            searchQuery: query,
            documentType: groupId,
          });

          const relativeUrl = url.replace(wixCodeApi.location.baseUrl, '');
          wixCodeApi.location.to?.(relativeUrl);
        });
      }
    },
  };
}
