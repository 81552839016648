import * as Sdk from '@wix/client-search-sdk';
import { CategoryList } from '@wix/search-results-settings-definitions';
import {
  FederatedSuggestions,
  SuggestionGroup,
  SuggestionItem,
} from '@wix/search-box-v2';

import { ISearchLocation } from '../../../../lib/location';
import { limitSuggestions } from './limitSuggestions';

const maxSuggestionDescriptionLength = 200;

export const convertResponseToSuggestions = ({
  federatedResponse,
  categoryList,
  searchQuery,
  footerUrl,
  searchLocation,
  absoluteUrl,
}: {
  federatedResponse: Sdk.FederatedSuggestions;
  categoryList: CategoryList;
  searchQuery: string;
  footerUrl: string;
  searchLocation: ISearchLocation;
  absoluteUrl: string;
}): FederatedSuggestions => {
  const { results: groups } = federatedResponse;

  let globalIndex = 0;

  const createGroupItem = ({
    documentType,
    title,
  }: {
    documentType: Sdk.SearchDocumentType;
    title: string;
  }): SuggestionGroup => {
    const relativeUrl = searchLocation.encodePath({
      documentType,
      query: searchQuery,
    });

    const url = `${absoluteUrl}/${relativeUrl}`;
    return {
      type: 'group',
      url,
      title,
      link: {
        namespace: 'SearchResults',
        key: 'searchSuggestions.groupLinkText',
        default: 'Show all',
      },
      data: {
        groupId: documentType,
        query: searchQuery,
        url,
        type: 'group',
      },
    };
  };

  const createDocumentItem = (
    document: Sdk.ISearchDocument,
  ): SuggestionItem => {
    return {
      id: document.id,
      type: 'item',
      url: document.url,
      title: document.title,
      description: document.description?.substring(
        0,
        maxSuggestionDescriptionLength,
      ),
      image: document.image,
      data: {
        globalIndex: globalIndex++,
        groupId: document.documentType,
        query: searchQuery,
        url: document.url,
        type: 'item',
      },
    };
  };

  const items = groups
    .filter(
      (group) =>
        group.documents.length && categoryList[group.documentType].visible,
    )
    .map((group) => {
      if (categoryList[group.documentType].useOverride) {
        group.title = categoryList[group.documentType].override;
      }
      return group;
    })
    .sort(
      (a, b) =>
        categoryList[a.documentType].index - categoryList[b.documentType].index,
    )
    .reduce(limitSuggestions, [])
    .reduce<FederatedSuggestions['items']>(
      (result, group) => [
        ...result,
        createGroupItem(group),
        ...group.documents.map(createDocumentItem),
      ],
      [],
    );

  return {
    items,
    footer: {
      url: footerUrl,
      text: `Search All "${searchQuery}"`,
    },
  };
};
