import {
  ILocationSearchRequest,
  IEncodedLocationSearchRequest,
} from '../location.types';

import { decoders } from './serializers';

export function decodeSearchRequest({
  q,
  qc,
  page,
  sort,
  pmin,
  pmax,
  pc,
}: IEncodedLocationSearchRequest): ILocationSearchRequest {
  return {
    query: decoders.decodeQuery(q),
    ...(qc && { documentType: decoders.decodeDocumentType(qc) }),
    page: decoders.decodePageNumber(page),
    sortBy: decoders.decodeSortBy(sort),
    productMinPrice: decoders.decodeProductPrice(pmin),
    productMaxPrice: decoders.decodeProductPrice(pmax),
    productCollections: decoders.decodeProductCollections(pc),
  };
}
