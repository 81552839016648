import { SearchDocumentType } from '@wix/client-search-sdk';
import { Settings } from '@wix/search-results-settings-definitions';

import { VisibleCategories } from './SearchResultsControllerStore.types';

export const getVisibleCategories = (
  settings: Settings,
  availableDocumentTypes: SearchDocumentType[],
): VisibleCategories => {
  return Object.entries(settings.categoryList).filter(
    ([documentType, categorySettings]) =>
      categorySettings.visible &&
      availableDocumentTypes.includes(documentType as SearchDocumentType),
  );
};
