import { BiDocumentIds, SuggestionItems } from '../types';

export function getSuggestionsStats(suggestions: SuggestionItems) {
  const suggestionItems = suggestions.filter(
    (suggestion) => suggestion.type === 'item',
  );
  const resultCount = suggestionItems.length;

  const stats = suggestionItems.reduce((stats, suggestion) => {
    stats[suggestion.data.groupId] =
      (stats[suggestion.data.groupId] as number) + 1 || 1;
    return stats;
  }, {});

  const documentIds: BiDocumentIds = suggestionItems.reduce((acc, curr) => {
    if (!acc[curr.data.groupId]) {
      acc[curr.data.groupId] = [];
    }
    acc[curr.data.groupId].push(curr.id);
    return acc;
  }, {});

  return {
    documentIds: JSON.stringify(documentIds),
    resultCount,
    resultsArray: JSON.stringify(stats),
    stats,
  };
}
